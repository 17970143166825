<template>
  <page-content>
    <page-title heading="รายงานสรุปค่าสอนครู">
      <template v-slot:right>
        <b-button :disabled="!enable" variant="danger" @click="showExportOption = true">
          <b-spinner v-if="isExporting" label="exporting"></b-spinner>
          <span v-else>ดาวน์โหลดรายงานสรุป</span>
        </b-button>

        <export-modal v-model="isExporting" />
        <!-- <download-excel
          class="btn btn-danger cursor-pointer"
          :fields="exportFields"
          :fetch="fetchExport"
          name="รายงานสรุปค่าสอนครู.xls"
        >
          <b-spinner v-if="isExporting" label="exporting"></b-spinner>
          <span v-else>ดาวน์โหลดรายงานสรุป</span>
        </download-excel> -->
      </template>
    </page-title>

    <b-row>
      <b-col cols="12">
        <filter-fiscal-year-term v-model="formFiscalYearTerm">
        </filter-fiscal-year-term>
      </b-col>

      <b-col cols="12">
        <filter-master v-model="formfilter" :m-headquarter-id="
          !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
        " :m-division-id="
  !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
" :m-station-id="
  !authUser.canAccessAllStations ? authUser.mStationId : null
" :disabledInput="{
  headQuarter:
    !authUser.canAccessAllHeadQuarters &&
    authUser.mHeadquarterId !== null,
  division:
    !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
  station:
    !authUser.canAccessAllStations && authUser.mStationId !== null,
}" :hiddenInput="{ school: true, schoolClass: true, room: true }">
        </filter-master>

        <!-- <filter-master
          v-model="formfilter"
          :hiddenInput="{ school: true, schoolClass: true }"
        >
        </filter-master> -->
      </b-col>

      <b-col cols="12" class="mt-2 mb-2">
        <filter-date-between v-model="filterDateBetween"></filter-date-between>
      </b-col>

      <b-col sm="8" md="6">
        <b-form-group class="px-2">
          <b-input-group class="input-group-seamless">
            <b-form-input placeholder="ค้นหารายชื่อครูตำรวจแดร์หรือโรงเรียน" v-model="keyword"
              @keyup.enter="onKeywordSearched"></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="4" md="2" class="px-4 px-md-0">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched">ค้นหา</b-button>
      </b-col>
    </b-row>

    <report-transaction-table v-if="enable" ref="table" class="mt-4" :fields="fields" :params="tableParams"
      @seeDetail="onSeeDetail" @delete="onDelete" />

    <b-card v-else class="text-center mt-2">
      คลิ๊ก <b>"ค้นหา"</b> เพื่อเริ่มดาว์นโหลดข้อมูล
    </b-card>

    <create-transection v-model="showModal" :transactionId="transactionId" isReadOnly></create-transection>

    <option-modal title="เลือกรูปแบบการนำออกข้อมูล" name="exportOverviewOption" :options="exportOptions"
      v-model="showExportOption" @selected:type="exportReport">
    </option-modal>
  </page-content>
</template>

<script>
// import DownloadExcel from "vue-json-excel";
import { orderBy, sumBy, groupBy } from "lodash";
import { formatBuddhistBirthDate } from "../../helpers/datepicker-helper";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import CreateTransection from "../../components/modal/CreateTransection";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
// import CreateOrUpdateUser from "../../components/modal/CreateOrUpdateUser";
import ReportTransactionTable from "../../components/table/ReportTransaction";
import FilterMaster from "../../components/form/FilterMaster";
import FilterDateBetween from "../../components/form/FilterDateBetween";
import FilterFiscalYearTerm from "../../components/form/FilterFiscalYearTerm";
// import DeleteUser from "../../components/modal/DeleteUser";
import { Auth, TransectionType } from "../../models";
import reportMixin from "../../mixins/reportMixin";
import OptionModal from "../../components/modal/Option";
import ExportModal from "../../components/modal/Export";

const defaultValue = "ทั้งหมด";

export default {
  mixins: [reportMixin],

  components: {
    PageContent,
    PageTitle,
    CreateTransection,
    ReportTransactionTable,
    FilterMaster,
    FilterDateBetween,
    FilterFiscalYearTerm,
    OptionModal,
    ExportModal,
    // DownloadExcel,
  },

  data() {
    return {
      keyword: "",
      formfilter: {},
      filterDateBetween: {
        startDate: null,
        endDate: null,
      },
      deleteData: {},
      transactionId: null,
      isExporting: false,
      showModal: false,
      showModalDelete: false,
      colKey: "ยศ ชื่อ-สกุล",
      exportOrders: ["user_name", "yearTerm", "station", "school"],
      exportOrderDirections: ["asc", "desc", "asc", "asc"],

      showExportOption: false,

      exportOptions: [
        { value: "onePage", label: "นำออกรายงานรวมหน้าเดียว" },
        { value: "multiplePages", label: "นำออกรายงานแยกหลายหน้า" },
      ],

      formFiscalYearTerm: {
        fiscalYearFrom: defaultValue,
        termFrom: defaultValue,
        fiscalYearTo: defaultValue,
        termTo: defaultValue,
      },

      enable: false,
      tableParams: {},
    };
  },

  // watch: {
  //   formfilter: {
  //     deep: true,
  //     handler: "onFormFilterChanged",
  //   },
  // },

  computed: {
    authUser() {
      return Auth.user();
    },

    fields() {
      const transactionTypes = TransectionType.all().map((transactionType) => ({
        key: `sumPayment_${transactionType.id}`,
        label: `ค่าตอบแทน ${transactionType.m_transaction_name} (บาท)`,
        class: "text-right",
      }));

      const fields = [
        // { key: "order", label: "ลำดับ" },
        { key: "headquarter", label: "ภาค", sortable: true },
        { key: "division", label: "จังหวัด", sortable: true },
        { key: "station", label: "สถานี", sortable: true },
        { key: "user_name", label: "ยศ ชื่อ-สกุล", sortable: true },
        { key: "yearTerm", label: "ปีการศึกษา", sortable: true },
        { key: "school", label: "โรงเรียน", sortable: true },
        {
          key: "sum_class",
          label: "จำนวนห้องเรียน (ห้อง)",
          class: "text-center",
          sortable: true,
        },
        {
          key: "sum_student",
          label: "จำนวนนักเรียนจริง (คน)",
          class: "text-center",
        },
        // {
        //   key: "sum_plan_student",
        //   label: "จำนวนนักเรียนตามแผน (คน)",
        //   class: "text-center",
        //   sortable: true,
        // },
        // {
        //   key: "sumPayment",
        //   label: "ค่าตอบแทน (บาท)",
        //   class: "text-right",
        // },
        ...transactionTypes,
      ];

      return fields;
    },

    exportFields() {
      const transactionTypeExportFields = TransectionType.all().reduce(
        (acc, transactionType) => {
          this.$set(
            acc,
            `ค่าตอบแทน ${transactionType.m_transaction_name} (บาท)`,
            `sumPayment_${transactionType.id}`
          );

          return acc;
        },
        {}
      );

      return {
        หน่วย: "headquarter",
        จังหวัด: "division",
        สถานี: "station",
        "ยศ ชื่อ-สกุล": "user_name",
        // เลขประจำตัวผู้ขอ: "user_id_governmen",
        ปีการศึกษา: "yearTerm",
        โรงเรียน: "school",
        "จำนวนนักเรียนจริง (คน)": "sum_student",
        // "จำนวนนักเรียนตามแผน (คน)": "sum_plan_student",
        "จำนวนห้องเรียน (ห้อง)": "sum_class",
        // "ค่าตอบแทน (บาท)": "sumPayment",
        ...transactionTypeExportFields,
      };
    },

    // tableParams() {
    //   const { formfilter, filterDateBetween: dateParams } = this;

    //   return {
    //     ...formfilter,
    //     ...this.getFilterTermYear(),
    //     ...dateParams,
    //   };
    // },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);

      const { formfilter, filterDateBetween: dateParams } = this;

      this.enable = false;

      this.tableParams = {
        ...formfilter,
        ...this.getFilterTermYear(),
        ...dateParams,
      };

      this.enable = true;
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    getFilterTermYear() {
      let params = {};

      if (
        this.formFiscalYearTerm.fiscalYearFrom &&
        this.formFiscalYearTerm.fiscalYearFrom !== defaultValue
      ) {
        this.$set(
          params,
          "fiscalYearFrom",
          this.formFiscalYearTerm.fiscalYearFrom
        );
      }

      if (
        this.formFiscalYearTerm.termFrom &&
        this.formFiscalYearTerm.termFrom !== defaultValue
      ) {
        this.$set(params, "termFrom", this.formFiscalYearTerm.termFrom);
      }

      if (
        this.formFiscalYearTerm.fiscalYearTo &&
        this.formFiscalYearTerm.fiscalYearTo !== defaultValue
      ) {
        this.$set(params, "fiscalYearTo", this.formFiscalYearTerm.fiscalYearTo);
      }

      if (
        this.formFiscalYearTerm.termTo &&
        this.formFiscalYearTerm.termTo !== defaultValue
      ) {
        this.$set(params, "termTo", this.formFiscalYearTerm.termTo);
      }

      return params;
    },

    async fetchExport() {
      let data = [];

      this.isExporting = true;

      try {
        data = await this.$refs.table.fetch({ downloadAll: true });
      } catch (error) {
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลรายงานสรุปได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isExporting = false;
      }

      return data;
    },

    exportByType(type, data) {
      let wb;

      if (type === "onePage") {
        wb = this.exportOnePage(data);
      }

      if (type === "multiplePages") {
        wb = this.exportMultiplePage(data);
      }

      return wb;
    },

    exportOnePage(data) {
      let exportFields = { ...this.exportFields };

      if (this.formfilter.mStationId) {
        this.$delete(exportFields, "สถานี");
      }

      let header = Object.keys(exportFields);

      const wb = XLSX.utils.book_new();

      let sheetHeader = ["ลำดับ", ...header];

      data = orderBy(data, this.exportOrders, this.exportOrderDirections);

      data = data.map((record, index) => {
        const res = {
          ลำดับ: index + 1,
        };

        header.forEach((col) => {
          res[col] = record[exportFields[col]];
        });

        return res;
      });

      let ws = XLSX.utils.json_to_sheet(data, {
        header: sheetHeader,
      });

      let { startDate, endDate } = this.filterDateBetween;

      if (startDate || endDate) {
        if (startDate) {
          startDate = formatBuddhistBirthDate(new Date(startDate));
        }

        if (endDate) {
          endDate = formatBuddhistBirthDate(new Date(endDate));
        }

        ws = this.$_reportMixin_addDate(ws, { startDate, endDate });
      }

      const transactionTypeMergedCols = TransectionType.all().reduce(
        (acc, transactionType) => {
          this.$set(
            acc,
            `ค่าตอบแทน ${transactionType.m_transaction_name} (บาท)`,
            (records) => {
              return sumBy(records, "v");
            }
          );

          return acc;
        },
        {}
      );

      ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
        orderCol: "A",
        mergedCol: {
          "จำนวนห้องเรียน (ห้อง)": (records) => {
            return sumBy(records, "v");
          },
          // "ค่าตอบแทน (บาท)": (records) => {
          //   return sumBy(records, "v");
          // },
          ...transactionTypeMergedCols,
        },
      });

      ws = this.$_reportMixin_setWidthColumn(ws, sheetHeader, data);

      XLSX.utils.book_append_sheet(wb, ws, "สรุปค่าสอนครู");

      return wb;
    },

    exportMultiplePage(data) {
      let exportFields = { ...this.exportFields };

      let header = Object.keys(exportFields);

      const wb = XLSX.utils.book_new();

      let sheetHeader = ["ลำดับ", ...header];

      let groupedData = {};

      if (this.formfilter.mStationId) {
        groupedData = groupBy(data, "station");
      } else {
        groupedData = groupBy(data, "division");
      }

      for (const groupName in groupedData) {
        if (Object.hasOwnProperty.call(groupedData, groupName)) {
          let groupRecords = groupedData[groupName];

          if (groupRecords && groupRecords.length) {
            groupRecords = orderBy(
              groupRecords,
              this.exportOrders,
              this.exportOrderDirections
            );

            groupRecords = groupRecords.map((record, index) => {
              const res = {
                ลำดับ: index + 1,
              };

              header.forEach((col) => {
                res[col] = record[exportFields[col]];
              });

              return res;
            });
          }

          let ws = XLSX.utils.json_to_sheet(groupRecords, {
            header: sheetHeader,
          });

          let { startDate, endDate } = this.filterDateBetween;

          if (startDate || endDate) {
            if (startDate) {
              startDate = formatBuddhistBirthDate(new Date(startDate));
            }

            if (endDate) {
              endDate = formatBuddhistBirthDate(new Date(endDate));
            }

            ws = this.$_reportMixin_addDate(ws, { startDate, endDate });
          }

          const transactionTypeMergedCols = TransectionType.all().reduce(
            (acc, transactionType) => {
              this.$set(
                acc,
                `ค่าตอบแทน ${transactionType.m_transaction_name} (บาท)`,
                (records) => {
                  return sumBy(records, "v");
                }
              );

              return acc;
            },
            {}
          );

          ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
            orderCol: "A",
            mergedCol: {
              "จำนวนห้องเรียน (ห้อง)": (records) => {
                return sumBy(records, "v");
              },
              // "ค่าตอบแทน (บาท)": (records) => {
              //   return sumBy(records, "v");
              // },
              ...transactionTypeMergedCols,
            },
          });

          ws = this.$_reportMixin_setWidthColumn(ws, sheetHeader, groupRecords);

          // const ws = XLSX.utils.json_to_sheet(groupRecords, {
          //   header: ["ลำดับ", ...header],
          // });

          XLSX.utils.book_append_sheet(wb, ws, groupName);
        }
      }

      return wb;
    },

    async exportReport(type) {
      let data = await this.fetchExport();

      if (data && data.length) {
        const wb = this.exportByType(type, data);

        /* bookType can be any supported output type */
        const wopts = { bookType: "xlsx", bookSST: false, type: "array" };

        const wbout = XLSX.write(wb, wopts);

        /* the saveAs call downloads a file on the local machine */
        saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "รายงานสรุปค่าสอนครู.xlsx"
        );
      }
    },

    async onSeeDetail(item) {
      this.transactionId = item.id;
      this.showModal = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
};
</script>
